<template>
	<MhRouterView class="OvFutureExhibitionsView view background2--color-primary-background" :isContentReady="isContentReady">

		<div class="minHeight minHeight--100 vSpace vSpace--viewContent">

			<OvMainHeadline
				:text="$t('title.upcomingExhibitions')"
			></OvMainHeadline>

			<OvSpacer type="default" :rows="6"></OvSpacer>

			<!-- Derzeit keine kommenden Ausstellungen -->
			<div class="hSpace hSpace--app maxWidth maxWidth--app" v-if="!app.futureExhibitions.length">
				{{$t('text.noUpcomingExhibitions')}}
			</div>

			<!-- Hauptausstellungen -->
			<div class="grid grid--exhibitionTeaserRow hSpace hSpace--app maxWidth maxWidth--app"
				 v-for="(exhibtions, i) in this.app.futureMainExhibitions" :key="'i'+i">
				<!-- OvExhibitionTeaser -->
				<div>
					<div class="vSpace vSpace--miniTitle font font--bold font--sizeMini" v-if="i==0 && ['xl', 'lg', 'dt'].includes( $mq )">
						{{_.upperFirst( $t('title.exhibition') )}}
					</div>
					<OvExhibitionTeaser
						class="hover hover--mainExhibtionTeaser shadow shadow--default"
						:class="['xl', 'lg', 'dt'].includes( $mq ) ? 'font font--sizeBig' : 'font font--sizeMedium'"
						:exhibitions="[exhibtions]"
					></OvExhibitionTeaser>
					<OvSpacer type="afterExhibitionTeaser"></OvSpacer>
				</div>
				<!-- OvEventsList -->
				<div>
					<template v-if="['xl', 'lg', 'dt'].includes( $mq )">
						<div class="vSpace vSpace--miniTitle font font--bold font--sizeMini" v-if="i==0">
							{{_.upperFirst( $t('title.events') )}}
						</div>
						<OvEventsList
							:layout="'widget'"
							:filter="{ category : 'belongsTo', belongsToId : exhibtions.id }"
							:exposeIds="_.map(app.futureMainExhibitions, 'id')"
							:showCalendarLink="false"
						></OvEventsList>
					</template>
				</div>
			</div>
			<!-- Weitere Ausstellungen -->
			<div class="grid grid--exhibitionTeaserRow hSpace hSpace--app maxWidth maxWidth--app"
				 v-for="(exhibtions, j) in this.app.futureOtherExhibitions" :key="'j'+j">
				<!-- OvExhibitionTeaser -->
				<div>
					<OvExhibitionTeaser
						class="shadow shadow--default hover hover--furtherExhibtionTeaser border border--furtherExhibtionTeaser"
						:class="['xl', 'lg', 'dt'].includes( $mq ) ? 'font font--sizeBig' : 'font font--sizeMedium'"
						:exhibitions="[exhibtions]"
					></OvExhibitionTeaser>
					<!--
					<OvSpacer type="default" :rows="1"></OvSpacer>
					-->
				</div>
				<!-- OvEventsList -->
				<div>
					<template v-if="['xl', 'lg', 'dt'].includes( $mq )">
						<OvEventsList
							:layout="'widget'"
							:filter="{ category : 'belongsTo', belongsToId : exhibtions.id }"
							:showCalendarLink="false"
						></OvEventsList>
						<OvSpacer type="default" :rows="1"></OvSpacer>
					</template>
				</div>
			</div>

		</div>

		<OvMainFooter></OvMainFooter>

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js' // TODO: remove this
	import RestHandler from '@/components/RestHandler/RestHandler.js'

	import MhRouterView from '@/components/MhRouterView/MhRouterView.vue'
	import OvMainHeader from '@/components/OvMainHeader.vue'
	import OvMainHeadline from '@/components/OvMainHeadline.vue'
	import OvExhibitionTeaser from '@/components/OvExhibitionTeaser.vue'
	import OvEventsList from '@/components/OvEventsList.vue'
	import OvMainFooter from '@/components/OvMainFooter.vue'
	import OvSpacer from '@/components/OvSpacer.vue'

	export default {
		name: 'OvFutureExhibitionsView',
		components: {
			MhRouterView,
			OvMainHeader,
			OvMainHeadline,
			OvExhibitionTeaser,
			OvEventsList,
			OvMainFooter,
			OvSpacer,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data() {
			return {
				isContentReady : false,
			}
		},
		watch: {
			'app.futureMainExhibitions' : {
				immediate: true,
				handler( to, from, doLog = true ) {
					if( doLog ){
						console.groupCollapsed(this.$options.name, '• watch app.futureMainExhibitions', from, to)
						console.groupEnd()
					}

					if( to ) this.isContentReady = true
				},
			},
			/*
			restHandler__pendingRequests: {
				handler( to, from, doLog = true ) {
					const icon = to ? '❌' : '✅'

					if( doLog ){
						console.groupCollapsed(this.$options.name, '• watch restHandler__pendingRequests', from, to, icon)
						console.groupEnd()
					}

					if( !to ) this.isContentReady = true
				},
			},
			*/
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
		},
		methods: {},
		mounted(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.OvFutureExhibitionsView {
		//background-color: yellow;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
